@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html,
body {
  background: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

.bg-primary-custom {
  background: #327a57 !important;
}

.bg-primary-red {
  background: #c00 !important;
}

.main {
  padding: 60px 0;
}

.main .step_active {
  background-image: url("../../public/images/step-active.jpg");
  background-repeat: no-repeat;
  height: 130%;
}

.main .title-active {
  color: #fff;
  font-size: 18px;
  padding-top: 1px;
}

.main .step_none {
  background-image: url("../../public/images/step-no-active.jpg");
  background-repeat: no-repeat;
  height: 130%;
}

.main .title-none {
  color: #fff;
  font-size: 18px;
  padding-top: 1px;
}

.body-step .title {
  color: #1b1b1b;
  padding-top: 25px;
  padding-bottom: 10px;
  position: relative;
  border-bottom: 2px #ecebeb solid;
  font-weight: 300;
}

/* .body-step .title::before {
    position: absolute;
    content: '';
    width: 80px;
    height: 3px;
    background: #327a57;
    left: 0;
    bottom: -3px;
} */

hr {
  background: #ecebeb;
  height: 6px;
}

.body-step p {
  color: #1b1b1b;
  line-height: 25px;
  padding-top: 15px;
  font-size: 17px;
  font-weight: 500;
}

.btn-primary:focus {
  box-shadow: none !important;
}

.form-control {
  background: #ecebeb !important;
}

.form-control:focus {
  border-color: #327a57 !important;
  box-shadow: 0 0 0 0.25rem rgba(50, 122, 87, 0.25) !important;
}

.list-group-item {
  padding: 0.3rem 1rem !important;
}

.dropdown .btn:focus {
  box-shadow: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #327957c9;
}

/* .dropdown-toggle::after {
  display: none;
} */

/*TYPE NUMBER HIDDE ARROW*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
